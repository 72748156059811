<template>
  <v-card class="mx-auto" :disabled="loading" :loading="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'facturacion',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                loading
                  ? ''
                  : table_data.length > 0
                  ? `(${table_data.length})`
                  : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row v-if="!table_data.length" dense>
        <v-col cols="12" sm="12" md="9">
          <v-file-input
            v-model="document_file_0"
            accept=".xlsx"
            label="Archivo"
            dense
          />
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-btn block small color="primary" @click.prevent="getData()">
            Cargar
            <v-icon right> mdi-check </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else dense>
        <v-col cols="12" xs="12" md="9">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-btn block small color="default" @click="table_data = []">
            Cargar otro archivo
            <v-icon right> mdi-file </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :items="table_data"
            :search="table_search"
            :headers="table_headers"
            :loading="loading"
            :items-per-page="-1"
            :item-class="tableRowBg"
            dense
          >
            <!-- <template v-slot:item.action="{ item }">
              <v-tooltip
                v-if="item.insurance_payment_check && !item.stamp_id"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn class="mr-1" v-on="on" fab x-small color="error" dark>
                    <v-icon> mdi-alert </v-icon>
                  </v-btn>
                </template>
                <span v-text="'No se encuentra complemento de pago'" />
              </v-tooltip>
            </template> -->
          </v-data-table>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn small color="success" @click.prevent="saveData">
            Guardar información
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  toFormData,
  msgConfirm,
  msgAlert,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    FaqDlg,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      loading: false,
      document_file_0: null,
      table_data: [],
      table_search: "",
      table_headers: [
        { text: "#", filterable: false, value: "index" },
        { text: "Fila", filterable: true, value: "row" },
        { text: "Folio", filterable: true, value: "folio" },
        { text: "Doc. ID", filterable: true, value: "rha_bill_folio" },
        {
          text: "Fecha",
          filterable: true,
          value: "insurance_reception_date_time",
        },
        {
          text: "Promesa de pago",
          filterable: true,
          value: "insurance_pay_date",
        },
        {
          text: "Núm. trámite",
          filterable: true,
          value: "insurance_procedure",
        },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    tableRowBg: function (item) {
      if (item.registry) return "blue lighten-3";
      if (!item.rha_bill_id) return "red lighten-3";
      return "";
    },
    getData() {
      if (this.document_file_0 != null) {
        this.loading = true;
        this.table_data = [];

        Axios.post(
          URL_API + "/bills/insurance/reception/analyze",
          toFormData({
            document_file_0: this.document_file_0,
          }),
          headersToken(this.login.token)
        )
          .then((resp) => {
            if (resp.data.success) {
              if (resp.data.data.length > 0) {
                this.table_data = resp.data.data;
              } else {
                this.$swal.fire(
                  msgAlert(
                    "warning",
                    "No se encontraron registros en el archivo"
                  )
                );
              }
            } else {
              console.log(res.data.message);
              this.$swal.fire(msgAlert("error", "Formato de archivo invalido"));
            }
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.$swal.fire(msgAlert("error", "Error en lectura de archivo"));
            this.loading = false;
          });
      } else {
        this.$swal.fire(msgAlert("error", "Selecciona un archivo"));
      }
    },
    saveData() {
      this.$swal
        .fire(msgConfirm(`¿Confirma guardar la información?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.loading = true;

            Axios.post(
              URL_API + "/bills/insurance/reception/save",
              {
                insurance_receptions: this.table_data,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.getData();
              } else {
                console.log(resp.data.message);
              }

              this.loading = false;
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });
  },
};
</script>